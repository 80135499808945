<script setup>
import {computed, onMounted, reactive, ref} from "vue";
import {AgreementService} from "@/common/api.service";
import {useRoute} from "vue-router";
import moment from "moment";
import locale from 'plotly.js-locales/ru'
import Plotly from 'plotly.js-dist';
import {VuePlotly} from 'vue3-plotly'

Plotly.register(locale)

const route = useRoute()
const item = ref({})
const itemLoadResult = reactive({
  ok: undefined,
  message: '',
})
const showPlot = ref(false)

async function loadItem(id) {
  await AgreementService.item(id)
      .then(({data}) => {
        item.value = data
        itemLoadResult.ok = true
      }).catch((e) => {
        itemLoadResult.ok = false
        itemLoadResult.message = e.response.data.message
      })
}

await loadItem(route.params.id)

const chart = computed(() => {
  let r = []
  let i = 0;
  item.value.PAYMENTS_CHART.series.forEach((ch) => {
    let color = (i ==0)?'#1E90FF':'#3CB371'
    r.push({
      name: ch.name,
      type: 'bar',
      legendgrouptitle:{
        font: 'green'
      },
      marker: {
        color: color,
      },
      x: item.value.PAYMENTS_CHART.categories,
      y: ch.data
    });
    i++;
  })
  return r
})
const _r = computed(() => {
  return JSON.stringify({
    filters: {
      COUNTERPARTY: [item.value.COUNTERPARTY.ID]
    }
  })
})

function switchActive() {
  document.addEventListener('DOMContentLoaded', function () {
    let switchInput = document.querySelector('.switch-checkbox__input');
    let switchNames = document.querySelectorAll('.switch-container__name');
    let scheduleContainer = document.getElementById('js-schedule');

    switchInput.addEventListener('change', function () {
      updateActiveClass(this.checked);
    });

    switchNames.forEach(function (name, index) {
      name.addEventListener('click', function () {
        switchInput.checked = (index === 1);
        updateActiveClass(switchInput.checked);
      });
    });

    function updateActiveClass(checked) {
      switchNames[0].classList.toggle('active', !checked);
      switchNames[1].classList.toggle('active', checked);

      if (switchNames[1].classList.contains('active')) {
        scheduleContainer.classList.remove('hidden');
      } else {
        scheduleContainer.classList.add('hidden');
      }

    }
  });

}

onMounted(async () => {
  switchActive()
})
</script>

<template>
  <div class="main-wraper" id="js-main-wraper" style="padding-top: 80px">
    <MainHeader></MainHeader>
    <section class="content-wrapper" id="js-content-wrapper">
      <div class="container-fluid">
        <section class="page-title-container">
          <div class="page-title-container__col">
            <div class="page-breadcrumbs">
              <span class="stl-fw-600">Договоры</span> /
              <span class="page-breadcrumbs__item">Договор: {{ item.UF_SUBJECT }}</span>
            </div>
          </div>
          <div class="page-title-container__col page-title-container__controll">
          </div>
        </section>
        <!-- page-title-container -->
        <section class="page-container">
          <div class="row card-row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 card-col">
              <div class="box-stl card-holder">
                <div class="holder-title card-title">Общая информация</div>
                <div class="card-holder__body">
                  <div class="card-holder-row df-ac df-jsb">
                    <div class="card-holder-col card-holder-col--name">Статус</div>
                    <div class="card-holder-col">{{ item.UF_IS_ARCHIVE ? 'Исполнен' : 'Действующий' }}</div>
                  </div>
                  <div class="card-holder-row df-ac df-jsb">
                    <div class="card-holder-col card-holder-col--name">Контрагент</div>
                    <div class="card-holder-col df-ac">
                      <span class="stl-mr-5">{{ item.COUNTERPARTY.UF_NAME }}</span>
                      <router-link
                          :to="{name: 'ListContract', query: { _r: _r }}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                          <g clip-path="url(#clip0_137_693)">
                            <path
                                d="M11.6667 20.7778C11.4864 20.7778 11.311 20.7193 11.1667 20.6112L7.8334 18.1112C7.7299 18.0335 7.6459 17.9329 7.58804 17.8172C7.53019 17.7015 7.50006 17.5739 7.50006 17.4445V12.7612L1.6534 6.18367C1.23817 5.71523 0.967048 5.13685 0.872632 4.51803C0.778216 3.89921 0.864519 3.26629 1.12117 2.69534C1.37782 2.1244 1.79389 1.63971 2.31938 1.29955C2.84487 0.959393 3.45742 0.778227 4.0834 0.777832H15.9167C16.5427 0.778566 17.1551 0.960031 17.6803 1.30042C18.2056 1.64081 18.6214 2.12564 18.8778 2.69665C19.1342 3.26766 19.2202 3.90056 19.1255 4.51929C19.0309 5.13801 18.7596 5.71625 18.3442 6.1845L12.5001 12.7612V19.9445C12.5001 20.1655 12.4123 20.3775 12.256 20.5338C12.0997 20.69 11.8877 20.7778 11.6667 20.7778ZM9.16673 17.0278L10.8334 18.2778V12.4445C10.8336 12.2405 10.9086 12.0436 11.0442 11.8912L17.1009 5.077C17.3029 4.84873 17.4346 4.56703 17.4804 4.26571C17.5262 3.96439 17.4841 3.65625 17.359 3.3783C17.234 3.10034 17.0314 2.86438 16.7756 2.69874C16.5197 2.5331 16.2215 2.44482 15.9167 2.4445H4.0834C3.77877 2.44496 3.48075 2.53329 3.22507 2.69889C2.96939 2.86449 2.7669 3.10033 2.6419 3.37812C2.5169 3.65592 2.47469 3.96388 2.52034 4.26507C2.56598 4.56625 2.69754 4.84788 2.89923 5.07617L8.95673 11.8912C9.09208 12.0437 9.16679 12.2406 9.16673 12.4445V17.0278Z"
                                fill="#0D74BB"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_137_693">
                              <rect width="20" height="20" fill="white" transform="translate(0 0.777832)"/>
                            </clipPath>
                          </defs>
                        </svg>
                      </router-link>
                    </div>
                  </div>
                  <div class="card-holder-row df-ac df-jsb">
                    <div class="card-holder-col card-holder-col--name">Номер</div>
                    <div class="card-holder-col">{{ item.UF_NUMBER }}</div>
                  </div>
                  <div class="card-holder-row df-ac df-jsb">
                    <div class="card-holder-col card-holder-col--name">Дата заключения договора</div>
                    <div class="card-holder-col">{{
                        moment(item.UF_DATE_SIGN).format("DD.MM.YYYY")
                      }}
                    </div>
                  </div>
                  <div class="card-holder-row df-ac df-jsb">
                    <div class="card-holder-col card-holder-col--name">Предмет договора</div>
                    <div class="card-holder-col">{{ item.UF_SUBJECT }}
                    </div>
                  </div>
                  <div class="card-holder-row df-ac df-jsb">
                    <div class="card-holder-col card-holder-col--name">Сумма, руб.</div>
                    <div class="card-holder-col">{{ $filters.numberFormat(item.UF_AMOUNT, 2) }}</div>
                  </div>
                  <div class="card-holder-row df-ac df-jsb">
                    <div class="card-holder-col card-holder-col--name">Остаток по договору, руб.</div>
                    <div class="card-holder-col">{{ $filters.numberFormat(item.PAYMENTS_SUM_LEFT, 2) }}</div>
                  </div>
                  <div class="card-holder-row df-ac df-jsb"
                       v-if="item.PURCHASE_REQUEST && item.PURCHASE_REQUEST.ID > 0">
                    <div class="card-holder-col card-holder-col--name">Связанная заявка на закупку</div>
                    <div class="card-holder-col">
                      <router-link
                          :to="{name: 'CardPurchaseRequest', params: { id: item.PURCHASE_REQUEST.ID }}"
                      >
                        {{ item.PURCHASE_REQUEST.ID }}
                      </router-link>
                    </div>
                  </div>
                  <div class="card-holder-row df-ac df-jsb"
                       v-if="item.PURCHASE_REQUEST && item.PURCHASE_REQUEST.ID > 0">
                    <div class="card-holder-col card-holder-col--name">Ответственный</div>
                    <div class="card-holder-col">
                      {{ item.PURCHASE_REQUEST.FULL_NAME }}
                    </div>
                  </div>
                  <div class="card-holder-row df-ac df-jsb">
                    <div class="card-holder-col card-holder-col--name">Имеет доступ на чтение</div>
                    <div class="card-holder-col">Сидоров Сидр, Петров Петров, Иванов Иван, Семенов Сергей</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 card-col">
              <div class="box-stl card-holder">
                <div class="holder-title card-title">Расходы по контрагенту по годам</div>
                <div class="holder-responsive holder-responsive-scroll-y">
                  <table class="card-table card-table--w-half">
                    <thead>
                    <tr>
                      <th>Год</th>
                      <th style="text-align: right;">Сумма, руб.</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr :key="ag.ID" v-for="ag in item.COUNTERPARTY.AGREEMENTS_EXPENSES">
                      <td>{{ ag.year }}</td>
                      <td style="text-align: right;">{{ $filters.numberFormat(ag.sum, 2) }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="box-stl card-holder">
                <div class="holder-title card-title df-ac df-jsb">
                  Расходы по договору
                  <div class="switch-container" v-if="item.PAYMENTS.length>1">
                    <div class="df-ac c-filter--control-toggle">
                      <label>Показать график </label>
                      <Toggle v-model="showPlot"/>
                    </div>
                  </div>
                </div>
                <div class="holder-responsive">
                  <VuePlotly v-if="showPlot" :data="chart" :layout="{yaxis: {exponentformat: 'none'} }" :config="{locale:'ru'}"/>
                  <table class="card-table card-table--w-33">
                    <thead>
                    <tr>
                      <th>№ ПП</th>
                      <th>Дата ПП</th>
                      <th style="text-align: right;">Сумма платежа, руб.</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr :key="p.ID" v-for="p in item.PAYMENTS">
                      <td>{{ p.UF_NUMBER }}</td>
                      <td>{{
                          moment(p.UF_DATE).format("DD.MM.YYYY")
                        }}
                      </td>
                      <td style="text-align: right;">{{ $filters.numberFormat(p.UF_AMOUNT, 2) }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- page-container -->
      </div>
    </section>
    <MainFooter></MainFooter>
  </div>
</template>

import {ListService} from "@/common/api.service";

const directoryStore = {
    state() {
        return {
            simple: {
                ei: [],
                purchase_plan_methods: [],
                okato: [],
                cost_centers: [],
                fin_sources: [],
                expenses_types: [],
                cec: [],
                etc: []
            },
        }
    },
    getters: {
        directory(state) {
            return state.directory;
        },
        simpleItem: (state) => (directory, id) => {
            let r = state.simple[directory].find(item => item.ID === parseInt(id))
            if (r !== undefined) return r
            return {}
        },
        simpleItemCode: (state) => (directory, code) => {
            let r = state.simple[directory].find(item => item.UF_CODE === code)
            if (r !== undefined) return r
            return {}
        }
    },
    actions: {
        ['fetchDirectory']({state}, d) {
            let r = {}
            Object.keys(d).forEach((k) => {
                r[k] = {
                    'type': d[k]
                }
            })

            return ListService.list(r)
                .then(({data}) => {
                    Object.keys(r).forEach((k) => {
                        state[r[k].type][k] = data[k];
                    })
                })
                .catch(error => {
                    throw new Error(error);
                });
        }
    },
    mutations: {}
}

export default directoryStore;
export default {
    modalListenerAdd: false,
    showModal(id) {
        document.body.classList.add('modal-open')
        document.body.style["overflow"] = 'hidden'
        document.body.style["padding-right"] = '0px'

        let d = document.createElement('div');
        d.classList.add('modal-backdrop')
        d.classList.add('show')
        document.body.appendChild(d)

        document.querySelector(id).classList.add('show')
        document.querySelector(id).style["display"] = 'block'

        if (!this.modalListenerAdd) {
            window.addEventListener('popstate', () => {
                this.closeModal()
            })
            this.modalListenerAdd = true
        }
    },
    closeModal(id) {
        document.body.classList.remove('modal-open')
        document.body.style["overflow"] = ''
        document.body.style["padding-right"] = ''
        if (document.querySelector('.modal-backdrop')) {
            document.querySelector('.modal-backdrop').remove()
        }

        if (id !== undefined && document.querySelector(id)) {
            document.querySelector(id).classList.remove('show')
            document.querySelector(id).style["display"] = ''
        }
    },
    strDigMonths() {
        return [
            "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"
        ]
    },
    yearList(start, end) {
        let r = []
        for (let i = start; i <= end; i++)
            r.push(i)
        return r
    },
    showField(c, f) {
        if (c.display_as_config && c.display_as_config.path) {
            let p = c.display_as_config.path.split('.')
            let v = f[p[0]]
            p.forEach(function (p, i) {
                if (v === undefined || v === null) {
                    return
                }
                if (i > 0) {
                    v = v[p]
                }
            })
            return v
        }
        return f[c.name]
    }
}
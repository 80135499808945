<script setup async>
import {LOGOUT, UPDATE_USER_ROLE} from "@/store/actions.type";
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {vClickOutside} from "@/directives/vClickOutside";
import {API_URL} from "@/common/config";

const store = useStore()
const router = useRouter()

const showRole = ref(0)
const changeRoleModel = ref()
const ag = ref([])
const userName = ref("")
let userActiveGroup = ref("")

function showRoleShift(r) {
  showRole.value = r
}

async function changeRole() {
  store
      .dispatch(UPDATE_USER_ROLE, changeRoleModel.value)
      .then(async () => {
        await router.push({name: "ListPurchase"})
        window.location.reload();
      });
}

const showHeaderUserNav = ref(false)

function hideHeaderUserNav() {
  showHeaderUserNav.value = false
}

async function logout() {
  await store
      .dispatch(LOGOUT)
      .then(() => {
        //router.push({name: "HomePage", query: {logout: 1}})
        window.location.href =
            API_URL + "auth/openid/logout"
      });
}

onMounted(async () => {
  userName.value = store.getters.currentUser.FULL_NAME
  changeRoleModel.value = store.getters.currentUser.ACTIVE_GROUP.ID
  ag.value = store.getters.currentUser.AVAILABLE_GROUPS
  userActiveGroup.value = store.getters.currentUser.ACTIVE_GROUP.NAME
})

</script>
<template>
  <div class="header-user">
    <div class="header-user-container" id="js-header-user-container">
      <div @click="showHeaderUserNav = !showHeaderUserNav">
        <span class="header-user-name" id="js-header-user-name">{{ userName }}</span>
        <i class="icon-item icon-arrow-white" :class="{'arrow-transform': !showHeaderUserNav}"
           id="js-header-user-icon"></i>
        <div class="header-user-role">{{ userActiveGroup }}</div>
      </div>

      <nav
          :class="['header-user-nav', {'stl-db': showHeaderUserNav}]"
          id="js-header-user-nav"
          v-click-outside="{when: 'display', call: hideHeaderUserNav, active: showHeaderUserNav}"
      >
        <ul class="header-user-menu">
          <li class="header-user-menu__item">
            <router-link :to="{name: 'Profile'}">Профиль пользователя</router-link>
          </li>
          <li class="header-user-menu__item" v-if="ag.length">
            <a href="#" @click="showRoleShift(true)">Сменить роль</a>
          </li>
          <li class="header-user-menu__item"><a href="#" @click="logout">Выйти</a></li>
        </ul>
      </nav>
      <!-- header-user-menu -->
    </div>
    <!-- header-lk-user-container -->
    <a href="https://lkfrprf.bitrix24.site/" target="_blank" class="header-user-help"></a>
  </div>
  <div class="modal show" v-if="showRole" style="display: block" tabindex="-1">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Изменить роль</h5>
          <button type="button" @click="showRoleShift(false)" class="btn-close" data-bs-dismiss="modal"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Укажите роль</label>
              <select v-model="changeRoleModel" class="c-input c-select">
                <option :key="g.ID" v-for="g in ag"
                        :value="g.ID">
                  {{ g.NAME }}
                </option>
              </select>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" data-bs-dismiss="modal"
                  @click="showRoleShift(false)">
            отменить
          </button>
          <button type="button" class="btn btn-blue" @click="changeRole()">изменить</button>
        </div>
      </div>
    </div>
  </div>
</template>
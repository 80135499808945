export const CHECK_AUTH = "checkAuth";
export const LOGIN = "login";
export const REFRESH_TOKEN = "refreshToken";
export const LOGIN_ECP = "loginEcp";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const UPDATE_USER_ROLE = "updateUserRole";
export const GET_USER_ROLE = "getUserRole";
export const OAUTH = "oauth";

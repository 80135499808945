<script setup async>
import Pagination from "@/components/partial/Pagination.vue";
import {onMounted, ref, computed, watch, onUnmounted} from "vue";
import {PurchaseRequestService} from "@/common/api.service";
import {useRoute, useRouter} from 'vue-router';
import shared from "@/common/shared";
import JwtService from "@/common/jwt.service";
import {useToast} from "vue-toastification";

const route = useRoute()
const router = useRouter()
const toast = useToast()
const list = ref({
  columns: [],
  directories: {},
  meta: {
    total: 0,
    per_page: 0,
    total_filtered: 0,
    IS_CAN_CREATE: false,
  }
});
const currentPage = ref(1)
const currentLimit = ref(10)
const request = ref({
  is_initial_load: 1,
  page: currentPage.value,
  limit: currentLimit.value,
  filters: {
    RESPONSIBLE_UOZ: [],
    CREATED_BY: [],
    STATUS: [],
    PM: []
  }
})
const checked = ref([])
const createPurchaseRequestData = ref({
  UF_PURCHASE_PLAN_ID: "",
})
const createDisable = ref(false)

if (route.query._r) {
  request.value = JSON.parse(route.query._r)
  currentPage.value = request.value.page
  currentLimit.value = request.value.limit
}

async function fetchList() {
  await PurchaseRequestService.list(request.value)
      .then(({data}) => {
        list.value = data
        if (data.order) {
          request.value.order = data.order
        }
        checked.value = []
        request.value.filters = data.filters
        if (!request.value.filters.RESPONSIBLE_UOZ) {
          request.value.filters.RESPONSIBLE_UOZ = []
        }
        if (!request.value.filters.CREATED_BY) {
          request.value.filters.CREATED_BY = []
        }
        if (!request.value.filters.STATUS) {
          request.value.filters.STATUS = []
        }
        if (!request.value.filters.PM) {
          request.value.filters.PM = []
        }
        request.value.order_empty = 1
        delete request.value.is_initial_load
        router.push({
          query: {
            _r: JSON.stringify(request.value)
          }
        })
      }).catch(async () => {
        await JwtService.destroyToken()
        await router.push({
          name: "Auth"
        })
      })
}

await fetchList()

const columnList = computed(() => {
  return list.value.columns.filter((el) => el.visible)
})
const availableIDList = computed(() => {
  let r = []
  list.value.meta.AVAILABLE_IDS.forEach(function (f) {
    r.push({value: f, label: f})
  })

  return r
})
const employeeUOZ = computed(() => {
  let r = []
  list.value.meta.USERS_EMPLOYEE_UOZ.forEach(function (f) {
    r.push({value: f.ID, label: f.FULL_NAME})
  })

  return r
})
const createdByList = computed(() => {
  let r = []
  list.value.meta.CREATED_BY_LIST.forEach(function (f) {
    r.push({value: f.ID, label: f.FULL_NAME})
  })

  return r
})
const statusList = computed(() => {
  let r = []
  list.value.meta.STATUS_FILTER_ITEMS.forEach(function (f) {
    r.push({value: f.ID, label: f.UF_NAME})
  })

  return r
})
const pmList = computed(() => {
  let r = []
  list.value.directories.purchase_plan_methods.forEach(function (f) {
    r.push({value: f.ID, label: f.UF_NAME})
  })

  return r
})
const pages = computed(() => {
  if (
      !list.value.meta.total_filtered ||
      list.value.meta.total_filtered <= list.value.meta.per_page
  ) {
    return [];
  }
  return [
    ...Array(Math.ceil(list.value.meta.total_filtered / list.value.meta.per_page)).keys()
  ].map(e => e + 1);
})

watch(currentPage, async () => {
  request.value.page = currentPage.value
  await fetchList()
})

watch(currentLimit, async () => {
  request.value.limit = currentLimit.value
  await fetchList()
})

async function changeOrder(f) {
  if (!request.value.order[f]) {
    request.value.order[f] = 'asc'
  } else if (request.value.order[f] === 'asc') {
    request.value.order[f] = 'desc'
  } else {
    delete request.value.order[f]
  }
  await fetchList()
}

function field(f) {
  let r = list.value.columns.find(el => el.name === f)
  if (r) {
    return r
  }
  return {}
}

async function createPurchase() {
  createDisable.value = true
  await PurchaseRequestService.create(createPurchaseRequestData.value)
      .then(async () => {
        shared.closeModal('#modal-purchase-plan')
        /*
        await router.push({
          name: "CardPurchaseRequest",
          params: {id: data.ID}
        });
         */
        window.location.reload();
      }).catch(({response}) => {
        createDisable.value = false
        toast.error(response.data.message, {
          timeout: 5000
        });
      })
}

/** пока не удаляем!!!
 function handleMultiselectTagClick() {
 document.addEventListener('click', function(event) {
 if (!event.target.classList.contains('multiselect-tag')) {
 const parent = event.target.closest('.multiselect-tags');

 if (!parent) {
 const multiselectTags = document.querySelectorAll('.multiselect-tags');
 multiselectTags.forEach(function(tag) {
 tag.classList.remove('show');
 });
 }
 } else {
 const multiselectTags = document.querySelectorAll('.multiselect-tags');
 multiselectTags.forEach(function(tag) {
 if (tag !== event.target.parentElement) {
 tag.classList.remove('show');
 }
 });
 event.target.parentElement.classList.toggle('show');
 }
 });
 }
 */

function handleMultiselectTagClick() {
  document.addEventListener('click', function (event) {
    const multiselectTags = document.querySelectorAll('.multiselect-tags');

    multiselectTags.forEach(function (tag) {
      if (tag !== event.target.closest('.multiselect-tags')) {
        tag.classList.remove('show');
      }
    });

    const parent = event.target.closest('.multiselect-tags');
    if (parent) {
      parent.classList.toggle('show');
    }
  });
}


onMounted(async () => {
  await router.isReady()
  handleMultiselectTagClick()
})

onUnmounted(() => {
  handleMultiselectTagClick()
})

async function resetFilter() {
  request.value.filters = {}
  await fetchList()
}
</script>

<template>
  <div class="main-wraper" id="js-main-wraper">
    <MainHeader></MainHeader>
    <!-- header -->
    <section class="content-wrapper" id="js-content-wrapper">
      <div class="container-fluid">
        <section class="page-title-container">
          <div class="page-title-container__col">
            <div class="page-breadcrumbs"><span class="stl-fw-600">{{ list.title }}</span></div>
          </div>
        </section>
        <!-- page-title-container -->
        <section class="box-stl c-filter--container">
          <div class="text-center" id="js-filter-show"><span class="c-filter--mob-link stl-tt-up"
                                                             onclick="filterMobileShow();">Развернуть фильтр</span>
          </div>
          <div class="c-filter--grid" id="js-filter-grid">
            <div class="c-filter__item">
              <Multiselect
                  placeholder="ID заявки"
                  class="c-input c-select c-filter__input c-multiselect-filter"
                  :disabled="!field('ID').searchable"
                  v-model="request.filters.ID"
                  mode="tags"
                  :searchable="true"
                  :options="availableIDList"
              />
            </div>
            <div class="c-filter__item" v-if="field('UF_SUBJECT').searchable">
              <input type="text" class="c-input c-filter__input" v-model="request.filters.UF_SUBJECT"
                     placeholder="Предмет закупки ">
            </div>
            <div class="c-filter__item" v-if="field('RESPONSIBLE_UOZ').searchable">
              <Multiselect
                  placeholder="Ответственный от УОЗ"
                  class="c-input c-select c-filter__input c-multiselect-filter"
                  v-model="request.filters.RESPONSIBLE_UOZ"
                  mode="tags"
                  :searchable="true"
                  :options="employeeUOZ"
              />
            </div>
            <div class="c-filter__item">
              <Multiselect
                  placeholder="Инициатор"
                  class="c-input c-select c-filter__input c-multiselect-filter"
                  :disabled="!field('CREATED_BY').searchable"
                  v-model="request.filters.CREATED_BY"
                  mode="tags"
                  :searchable="true"
                  :options="createdByList"
              />
            </div>
            <div class="c-filter__item">
              <Multiselect
                  placeholder="Статус заявки"
                  class="c-input c-select c-filter__input c-multiselect-filter"
                  v-model="request.filters.STATUS"
                  mode="tags"
                  :searchable="true"
                  :options="statusList"
              />
            </div>
            <div class="c-filter__item" v-if="field('PM').searchable">
              <Multiselect
                  placeholder="Способ закупки"
                  class="c-input c-select c-filter__input c-multiselect-filter"
                  v-model="request.filters.PM"
                  mode="tags"
                  :searchable="true"
                  :options="pmList"
              />
            </div>
            <div class="c-filter__item c-filter__item-md c-filter__item-data-inp" v-if="field('PLACEMENT').searchable">
              <VueDatePicker v-model="request.filters.PLACEMENT" format="MM.yyyy" :month-picker="true"
                             model-type="format"
                             :placeholder="'Планируемый срок размещения'"></VueDatePicker>
            </div>
            <div class="c-filter__item c-filter__item-md c-filter__item-data-inp"
                 v-if="field('UF_PLANNED_DATE_DOCUMENTATION').searchable">
              <VueDatePicker v-model="request.filters.UF_PLANNED_DATE_DOCUMENTATION" format="MM.yyyy"
                             :month-picker="true"
                             model-type="format"
                             :placeholder="'Срок формирования документации'"></VueDatePicker>
            </div>

            <div class="c-filter__controll">
              <div class="btn btn-reset" @click="resetFilter">СБРОС</div>
              <div class="btn btn-blue" @click="fetchList">Применить</div>
            </div>
          </div>
          <!-- c-filter--grid -->
        </section>
        <!-- c-filter--container -->
        <section class="page-container">
          <div class="box-stl c-table--container">
            <div class="holder-responsive">
              <table class="c-table">
                <thead>
                <tr>
                  <th :key="column.name" v-for="(column, index) in columnList"
                      :class="{ 'th-text-center': index === columnList.length - 1 }"
                  >
                    <div class="sorting-holder" v-if="column.type==='column'">
                      <span v-if="column.orderable">{{ column.title }}</span>
                      <span v-else class="sorting-holder__full-title">{{ column.title }}</span>
                      <span @click="changeOrder(column.name)" v-if="column.orderable"
                            class="sorting-link" :class="{
                                            'active': list.order[column.name] === 'desc',
                                            'active-up': list.order[column.name] === 'asc'
                                        }"></span>
                    </div>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr :key="item.ID" v-for="item in list.data">
                  <td :key="column.name" v-for="column in columnList">
                                        <span v-if="column.display_as === 'text' || column.display_as === 'text_path'"
                                              :style="{
                      'white-space': column.modifiers.find(val => val === 'white-space-pre')?'pre':'',
                      'word-break': column.modifiers.find(val => val === 'break-all')?'break-all':''
                    }"
                                        >{{
                                            shared.showField(column, item)
                                          }}
                                        </span>
                    <router-link
                        :to="{name: 'CardPurchaseRequest', params: { id: item['ID'] }, query: { _r: route.query._r }}"
                        v-if="column.display_as === 'router_link'">
                      {{ shared.showField(column, item) }}
                    </router-link>
                    <label class="input-checbox-area"
                           style="margin: 0 auto"
                           v-if="column.display_as === 'checkbox_bool' && shared.showField(column, item)">
                      <input type="checkbox" class="" checked disabled>
                      <span class="input-checbox-label"></span>
                    </label>
                    <label class="input-checbox-area"
                           style="margin: 0 auto"
                           v-if="column.display_as === 'checkbox_bool' && !shared.showField(column, item)">
                      <input type="checkbox" class="" disabled>
                      <span class="input-checbox-label"></span>
                    </label>
                  </td>
                </tr>
                </tbody>
              </table>
              <Pagination
                  :pages="pages"
                  v-model="currentPage"
                  :total="list.meta.total_filtered"
                  :perPage="currentLimit"
                  @update:perPage="currentLimit = $event"
              ></Pagination>
            </div>
          </div>
        </section>
        <!-- page-container -->
      </div>
    </section>
    <!-- content-wrapper -->
    <MainFooter>
      <div class="footer-right__controll">
        <button v-if="list.meta.IS_CAN_CREATE" class="btn btn-green"
                @click="shared.showModal('#modal-purchase-plan')">Создать заявку на закупку
        </button>
      </div>
    </MainFooter>
    <!-- footer -->
  </div>
  <!-- main-wraper -->

  <!--  Создание закупки в план  -->
  <div class="modal" id="modal-purchase-plan" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-scrollable modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Создать заявку на закупку</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#modal-purchase-plan')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">План закупок<span
                  class="c-c-danger">*</span></label>
              <select v-model="createPurchaseRequestData.UF_PURCHASE_PLAN_ID" class="c-input c-select">
                <option :value="i.ID"
                        :key="i.ID" v-for="i in list.meta.PURCHASE_PLAN_LIST">{{ i.ID + " " + i.UF_SUBJECT }}
                </option>
              </select>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#modal-purchase-plan')">
            отменить
          </button>
          <button type="button" class="btn btn-blue" :disabled="createDisable"  @click="createPurchase">создать</button>
        </div>
      </div>
    </div>
  </div>
</template>

<template>
  <div class="modal show" tabindex="-1" style="display: block;">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ signTitle }}</h5>
          <button type="button" class="btn-close" @click="closeModal"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="model-text" style="color:red">
              {{errorText}}
            </div>
            <div class="modal-text">
              <div class="dg-modal-body__col" v-if="!ecpPluginEnabled">
                <label class="dg-modal__label" style="color: red;">Плагин не активен</label>
              </div>
              <div class="dg-modal-body__col" v-else>
                <label class="dg-modal__label" style="color: green;">Плагин загружен.</label>
              </div>
            </div>

            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Сертификат<span class="c-c-danger">*</span></label>
              <select v-model="ecpCert" class="c-input c-select">
                <option :key="cert.text" :value="cert" v-for="cert in ecpCerts">
                  {{ cert.text }}
                </option>
              </select>
            </div>
          </form>
          <div class="dg-modal-body__col dg-modal__label ecp-info-help">
            <a href="https://docs.cryptopro.ru/cades/plugin" target="_blank">Инструкция по установке ЭЦП</a>
            <br/>КриптоПро ЭЦП Browser plug-in
            <a href="https://www.cryptopro.ru/products/cades/plugin/get_2_0">скачать</a>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="closeModal">Нет</button>
          <button type="button" class="btn btn-blue"
                  :disabled="ecpCert===null"
                  @click="SignCadesBES_Async_File('1123')">Подписать</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*global cadesplugin*/
import "@/scripts/cadesplugin_api";
import { Buffer } from 'buffer';

export default {
  name: "ECP",
  data() {
    return {
      ecpPluginEnabled: false,
      ecpCert: null,
      ecpCerts: [],
      toasterErrors: [],
      errorText: "",
    }
  },
  props: {
    signCallback: {
      type: Function,
      required: true
    },
    closeModal: {
      type: Function,
      required: false
    },
    signData: {
      type: String,
      required: true
    },
    signTitle: {
      type: String,
      required: true
    }
  },
  async mounted() {
    this.Common_CheckForPlugIn();
  },
  methods: {
    Common_CheckForPlugIn() {
      cadesplugin.set_log_level(cadesplugin.LOG_LEVEL_DEBUG);
      this.ecpPluginEnabled = true;
      let _this = this;
      cadesplugin.async_spawn(function* () {
        //let oAbout = yield cadesplugin.CreateObjectAsync("CAdESCOM.About");
        yield _this.FillCertList_Async();
      });
    },
    FillCertList_Async() {
      let _this = this;
      cadesplugin.async_spawn(function* () {
        let oStore;
        try {
          oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
          if (!oStore) {
            alert("Ошибка чтения сертификатов ЭЦП");
            return;
          }

          yield oStore.Open();
        } catch (ex) {
          alert("Ошибка чтения сертификатов ЭЦП");
        }

        let CertificatesObj = yield oStore.Certificates;

        let certCnt = yield CertificatesObj.Count;
        if (certCnt === 0) {
          //throw "Сертификаты не найдены";
        }
        try {
          yield oStore.Open(cadesplugin.CADESCOM_CONTAINER_STORE);
          let certs = yield oStore.Certificates;
          let certCnt = yield certs.Count;
          for (let i = 1; i <= certCnt; i++) {
            let cert = yield certs.Item(i);

            let oOpt = {
              text: "",
              value: ""
            };
            oOpt.text = yield cert.SubjectName;
            oOpt.value = yield cert.Thumbprint;
            _this.ecpCerts.push(oOpt);
          }
          yield oStore.Close();
        } catch (ex) {
          console.log(ex);
        }
        console.log(_this.ecpCerts)
      });
    },
    SignCadesBES_Async_File() {
      let _this = this;
      _this.errorText = '';
      cadesplugin.async_spawn(function* () {
        try {
          //FillCertInfo_Async(certificate);
          let oSigner;
          let errormes = "";
          try {
            oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
          } catch (err) {
            errormes = "Failed to create CAdESCOM.CPSigner: " + err.number;
            throw errormes;
          }
          let oSigningTimeAttr = yield cadesplugin.CreateObjectAsync(
              "CADESCOM.CPAttribute"
          );

          let CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME = 0;
          yield oSigningTimeAttr.propset_Name(
              CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME
          );
          let oTimeNow = new Date();
          yield oSigningTimeAttr.propset_Value(oTimeNow);
          let attr = yield oSigner.AuthenticatedAttributes2;
          yield attr.Add(oSigningTimeAttr);

          let oDocumentNameAttr = yield cadesplugin.CreateObjectAsync(
              "CADESCOM.CPAttribute"
          );
          let CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME = 1;
          yield oDocumentNameAttr.propset_Name(
              CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME
          );
          yield oDocumentNameAttr.propset_Value("Document Name");
          yield attr.Add(oDocumentNameAttr);

          let oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
          try {
            oStore.Open(cadesplugin.CADESCOM_CONTAINER_STORE);
          } catch (e) {
            alert("Ошибка при открытии хранилища");
            console.log(e);
            return;
          }

          let CertificatesObj = yield oStore.Certificates;
          let CAPICOM_CERTIFICATE_FIND_SHA1_HASH = 0;
          let oCerts = yield CertificatesObj.Find(
              CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
              _this.ecpCert.value
          );

          if (oCerts.Count == 0) {
            errormes = "Certs not found";
            throw errormes;
          }

          console.log('oCerts', oCerts)
          let oCert = yield oCerts.Item(1);
          console.log('oCert', oCert)
          let sName = yield oCert.SubjectName;

          if (oSigner) {
            yield oSigner.propset_Certificate(oCert);
          } else {
            errormes = "Failed to create CAdESCOM.CPSigner";
            throw errormes;
          }

          let oSignedData = yield cadesplugin.CreateObjectAsync(
              "CAdESCOM.CadesSignedData"
          );
          let CADES_BES = 1;
          let buff = new Buffer(_this.signData);
          let dataToSign = buff.toString('base64');
          if (dataToSign) {
            yield oSignedData.propset_ContentEncoding(1); //CADESCOM_BASE64_TO_BINARY
            yield oSignedData.propset_Content(dataToSign);
            yield oSigner.propset_Options(1); //CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN

            _this.ecpValue = yield oSignedData.SignCades(oSigner, CADES_BES);
            _this.showECP = false;
            _this.signCallback(_this.ecpValue)
            let sn = sName.split(",");
            sn.forEach(function (v) {
              let r = v.replace("CN=", "");
              if (r.length !== v.length) {
                _this.ecpEnsurer = r.trim();
              }
            });

          }
          //alert(Signature)
        } catch (err) {
          console.log("sign err", err);
          _this.errorText = err.message;
        }
      });
    },
  }
}
</script>

<style scoped>

</style>
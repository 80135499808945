<script setup async>
import {onMounted, ref} from "vue";
import {useStore} from "vuex";

const store = useStore()
const ProfileUserName = ref("")
const ProfileUserLogin = ref("")
const ProfileUserEmail = ref("")
const ProfileUserRole = ref([])
//const ProfileUserPosition = ref("")

onMounted(async () => {
  ProfileUserName.value = store.getters.currentUser.FULL_NAME
  ProfileUserLogin.value = store.getters.currentUser.LOGIN
  ProfileUserEmail.value = store.getters.currentUser.EMAIL
  ProfileUserRole.value = store.getters.currentUser.AVAILABLE_GROUPS
  //ProfileUserPosition.value = store.getters.currentUser.LOGIN
})
</script>

<template>
  <div class="main-wraper" id="js-main-wraper">
    <MainHeader></MainHeader>
    <section class="content-wrapper" id="js-content-wrapper">
      <div class="container-fluid">
        <section class="page-title-container">
          <div class="page-title-container__col">
            <div class="page-title-aside df-ac">
              <div class="page-title">Профиль пользователя</div>
            </div>
          </div>
          <div class="page-title-container__col page-title-container__controll">
          </div>
        </section>
        <!-- page-title-container -->
        <section class="page-container">
          <div class="row card-row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 card-col">
              <div class="box-stl card-holder">
                <div class="card-holder__body">
                  <div class="card-holder-row df-ac df-jsb">
                    <div class="card-holder-col">Логин</div>
                    <div class="card-holder-col card-holder-col--confines">{{ProfileUserLogin}}</div>
                  </div>
                  <div class="card-holder-row df-ac df-jsb">
                    <div class="card-holder-col">ФИО</div>
                    <div class="card-holder-col card-holder-col--confines">{{ProfileUserName}}</div>
                  </div>
                  <div class="card-holder-row df-ac df-jsb">
                    <div class="card-holder-col">Email</div>
                    <div class="card-holder-col card-holder-col--confines">{{ProfileUserEmail}}</div>
                  </div>
                  <div class="card-holder-row df-ac df-jsb">
                    <div class="card-holder-col">Доступные роли</div>
                    <div class="card-holder-col card-holder-col--confines">
                      <span
                          v-for="(item, idx) of ProfileUserRole"
                          :key="item.NAME">
                        {{item.NAME}}<template v-if="idx<ProfileUserRole.length-1">, </template>
                      </span>
                    </div>
                  </div>
                  <div class="card-holder-row df-ac df-jsb">
                    <div class="card-holder-col">Должность</div>
                    <div class="card-holder-col card-holder-col--confines"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <MainFooter></MainFooter>
    <!-- footer -->
  </div>
</template>
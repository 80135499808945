<script setup async>

</script>
<template>
    <div class="main-wraper" id="js-main-wraper" style="padding-top: 80px">
        <MainHeader></MainHeader>
        <section class="content-wrapper" id="js-content-wrapper">
            <br/><br/>
            <div id="b24form"></div>
            <iframe
                    src="/support.html"
                    scrolling="no"
                    frameborder="0"
                    marginheight="0"
                    marginwidth="0"
                    style="width: 100%; height: 1568px; border: 0px; overflow: hidden; padding: 0px; margin: 0px;"
            ></iframe>
        </section>
        <MainFooter></MainFooter>
    </div>
</template>

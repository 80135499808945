<script setup async>
import {useToast} from "vue-toastification";
import JwtService from "@/common/jwt.service";
import ApiService from "@/common/api.service";
import {useRoute} from "vue-router";
import {ref} from "vue";

const route = useRoute()
const toast = useToast()
const credentials = ref({
    login: "",
    password: ""
})

function loginCheck() {
    if (route.query.redirect) {
        credentials.value.redirect = route.query.redirect;
    }
    JwtService.destroyToken()
    ApiService.post("auth/getAccessToken", credentials.value).then(
        ({ data }) => {
            window.location.href = "/?access_token=" + data.ACCESS_TOKEN;
        }
    ).catch(({response}) => {
        toast.error(response.data.message, {
            timeout: 5000
        });
    })
}
</script>
<template>
    <div class="container-fluid mycontainer-fluid body-wrapper">
        <div class="row myrow" style="height: 100%;">
            <div
                    class="col-xs-12 col-sm-6 col-md-7 col-lg-7 mycol auth-col-wrapper block-mobile-hidden"
            >
                <div class="login-bg frp-auth-bg"></div>
            </div>
            <div
                    class="col-xs-12 col-sm-6 col-md-5 col-lg-5 mycol frp-auth-bg-holder"
            >
                <div class="col-xs-12">
                    <div class="login-select">

                        <div
                                class="login-select__form login-padding-double frp-auth-area"
                        >
                            <div class="d-flex" style="color: rgb(221, 5, 43);">
                                <svg
                                        width="99"
                                        height="29"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                            d="M11.527 0v17.27L9.511 5.415 0 0v29l25.402-14.447L11.527 24.65V29l25.474-14.5L11.527 0z"
                                            fill="currentColor"
                                    ></path>
                                    <path
                                            d="M76.297 6.607c-2.817 0-4.292 1.337-4.797 1.914l-1.255-1.563h-2.477v20.96h4.405V22.13a6.604 6.604 0 004.072 1.327c2.81 0 5.81-1.637 5.81-5.822v-4.97c.01-3.797-2.106-6.059-5.758-6.059zm1.39 10.498c0 .9-.516 2.434-2.429 2.434-1.912 0-3.085-1.565-3.085-1.565V12.02s1.288-1.512 3.075-1.512c1.786 0 2.428 1.288 2.428 2.353l.01 4.244zM84.942 6.948v16.141h4.418V10.645h5.225v12.444H99V6.948H84.942zM60.15 6.58a5.2 5.2 0 00-2.003.374 5.14 5.14 0 00-1.698 1.114V1.461h-6.764v3.69h2.343v2.854a5.122 5.122 0 00-1.675-1.07 5.178 5.178 0 00-1.964-.356c-2.81 0-5.07 1.924-5.07 5.822v4.97c0 3.795 1.773 6.069 5.178 6.069a5.397 5.397 0 003.532-1.43v5.897h4.42v-5.956c.913.805 2.121 1.489 3.609 1.489 3.402 0 5.176-2.274 5.176-6.069v-4.97c-.021-3.898-2.274-5.822-5.083-5.822zM50.127 19.54c-1.783 0-2.428-1.29-2.428-2.354v-4.244c0-.902.516-2.434 2.428-2.434a2.815 2.815 0 011.903.793v7.504a3.16 3.16 0 01-1.903.724v.01zm10.705-2.364c0 1.065-.642 2.353-2.428 2.353a3.21 3.21 0 01-1.97-.788v-7.37a2.854 2.854 0 011.97-.853c1.91 0 2.428 1.525 2.428 2.434v4.224z"
                                            fill="#002333"
                                    ></path>
                                </svg>
                            </div>
                            <p class="frp-auth-subtitle">Добро пожаловать!</p>
                            <form @submit.prevent="loginCheck">
                                <div
                                        class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                                        style="margin-bottom: 12px;"
                                >
                                    <div class="frp-auth-label-holder">
                                        <label class="frp-auth-label" for="username">Логин</label>
                                    </div>
                                    <input
                                            v-model="credentials.login"
                                            id="username"
                                            class="form-control"
                                            type="text"
                                            placeholder="Имя пользователя"
                                            required=""
                                    />
                                </div>
                                <div
                                        class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                                        style="margin-bottom: 12px;"
                                >
                                    <div class="frp-auth-label-holder">
                                        <label class="frp-auth-label" for="userpassword"
                                        >Пароль</label
                                        >
                                    </div>
                                    <input
                                            v-model="credentials.password"
                                            id="userpassword"
                                            class="form-control"
                                            type="password"
                                            placeholder="Пароль"
                                            autocomplete="off"
                                    />
                                </div>
                                <div class="col-xs-12">
                                    <button
                                            type="submit"
                                            class="col-xs-12 col-sm-12 col-md-12 login-select__form-btn frp-auth-butt"
                                    >
                                        Войти
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /row myrow -->
    </div>
</template>

<style lang="css">
#app {
  height: 100%;
}
@import "/public/css/auth.css";
</style>
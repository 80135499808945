<script setup>

import moment from "moment";
import {
  OAUTH
} from "@/store/actions.type";
import {useRouter, useRoute} from 'vue-router';
import JwtService from "@/common/jwt.service";
import jwtDecode from "jwt-decode";
import {useStore} from "vuex";
import {onMounted} from "vue";
import {AUTH_URL} from "@/common/config";

const router = useRouter()
const route = useRoute()
const store = useStore()

onMounted(async () => {
  await router.isReady()

  if (typeof route.query.access_token != "undefined") {
    await store.dispatch(OAUTH, route.query.access_token).then(async () => {
        await router.push({name: "ListPurchase"});
    });
  } else if (!store.getters.isAuthenticated && route.name !== "Auth") {
    //if (IS_DEV_MODE) {
    //  await router.push({
    //    name: "Auth",
    //    query: {redirect: window.location.pathname + window.location.search}
    //  });
    //} else {
      window.location.href =
          AUTH_URL +
          "?redirect=" +
          window.location.pathname +
          window.location.search;
    //}
  } else if (store.getters.isAuthenticated && route.name === "Auth") {
    await store.dispatch(OAUTH, route.query.access_token).then(async () => {
      await router.push({name: "ListPurchase"});
    });
  }

  if (typeof store.getters.currentUser !== "undefined") {
    //this.newActiveGroup = store.getters.currentUser.ACTIVE_ROLE;
    if (
        typeof store.getters.currentUser.GROUP_ID !== "undefined" &&
        store.getters.currentUser.GROUP_ID.length > 1
    ) {
      this.roleChangeAvail = true;
    }
    if (!route.query.access_token) {
      let exp = jwtDecode(JwtService.getToken());
      let expDate = new Date();
      expDate.setTime(exp.exp * 1000);
      let date = new Date();
      if (
          expDate.getTime() <
          moment(date)
              .toDate()
              .getTime()
      ) {
        //alert("Будет осуществлен выход из системы по причине не активности!");
        this.logout();
      }
    }
  }
})
</script>
<template>
  <Suspense>
    <router-view/>
  </Suspense>
</template>




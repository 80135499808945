<script setup async>
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {AUTH_URL} from "@/common/config";

const router = useRouter()
const route = useRoute()
const store = useStore()
await router.isReady()

if (typeof route.query.logout != "undefined") {
  window.location.href =
      AUTH_URL +
      "?redirect=/";
}

if (store.getters.isAuthenticated) {
  await router.isReady().then(async () => {
    await router.push({name: "ListPurchase"});
  })
}

</script>
<template>
  <div>

  </div>
</template>

<script setup>

</script>

<template>
  <div class="main-wraper" id="js-main-wraper">
    <MainHeader></MainHeader>
    <section class="content-wrapper" id="js-content-wrapper">
    </section>
    <MainFooter></MainFooter>
  </div>
</template>

<style scoped>

</style>
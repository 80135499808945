import JwtService from '@/common/jwt.service'
import { API_URL } from '@/common/config'
import axios from 'axios'
import qs from 'qs'

axios.defaults.baseURL = API_URL

const ApiService = {
  setHeader () {
    if (JwtService.getToken() !== '' && JwtService.getToken() !== null) {
      axios.defaults.headers.common[
        'Authorization'
        ] = `Bearer ${JwtService.getToken()}`
    } else {
      delete axios.defaults.headers.common.Authorization
    }
  },
  
  async query (resource, params) {
    this.setHeader()
    
    return axios.get(resource, params).catch(error => {
      throw new Error(`ApiService ${error}`)
    })
  },
  
  get (resource, slug = '') {
    this.setHeader()
    
    return axios.get(`${resource}${slug}/`)
  },
  
  get2 (resource, slug = '') {
    this.setHeader()
    
    return axios.get(`${resource}${slug}`).catch(error => {
      throw new Error(`ApiService ${error}`)
    })
  },
  
  post (resource, params) {
    this.setHeader()
    
    return axios.post(`${resource}`, params)
  },
  
  postForm (resource, form) {
    this.setHeader()
    
    return axios.post(`${resource}`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  
  postEncoded (resource, form) {
    this.setHeader()
    
    return axios.post(`${resource}`, form, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  
  update (resource, slug, params) {
    this.setHeader()
    
    return axios.put(`${resource}/${slug}`, params)
  },
  
  put (resource, params) {
    this.setHeader()
    
    return axios.put(`${resource}`, params)
  },
  
  delete (resource) {
    this.setHeader()
    
    return axios.delete(resource).catch(error => {
      throw new Error(`ApiService ${error}`)
    })
  }
}
export const PurchaseService = {
  async list (params) {
    return ApiService.query('/purchase-plan/registry', {
      params:           params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { encode: false })
      }
    })
  },
  async item (id) {
    return ApiService.get(`/purchase-plan/${id}`)
  },
  async bulkStatusChange (params) {
    return ApiService.post(`/purchase-plan/bulk/status-change`, params)
  },
  async bulkApprove (params) {
    return ApiService.post(`/purchase-plan/bulk/approval`, params)
  },
  async statusChange (id, params) {
    return ApiService.post(`/purchase-plan/${id}/status/change`, params)
  },
  async create (params) {
    return ApiService.post(`/purchase-plan`, params)
  },
  async update (params) {
    return ApiService.put(`/purchase-plan/${params.ID}`, params)
  },
  async copy (id, params) {
    return ApiService.post(`/purchase-plan/${id}/copy`, params)
  },
  async makeChange (id) {
    return ApiService.post(`/purchase-plan/${id}/make-change`)
  },
  async excludePlan (id) {
    return ApiService.post(`/purchase-plan/${id}/exclude-plan`)
  },
  async cancel (id) {
    return ApiService.post(`/purchase-plan/${id}/cancel`)
  },
  async revoke (id, params) {
    return ApiService.post(`/purchase-plan/${id}/revoke`, params)
  },
  async exportExcel () {
    return ApiService.post(`/purchase-plan/export/excel`)
  },
  async report (id, params) {
    return ApiService.post(`/report/export/${id}`, params)
  },
  async addDocument (id, params) {
    return ApiService.postEncoded(`/purchase-plan/${id}/documents`, params)
  },
  async deleteDocument (id, docId) {
    return ApiService.delete(`/purchase-plan/${id}/documents/${docId}`)
  },
  async statusHistory (id) {
    return ApiService.get(`/purchase-plan/${id}/status/history`)
  },
}
export const PurchaseRequestService = {
  async list (params) {
    return ApiService.query('/purchase-request/registry', {
      params:           params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { encode: false })
      }
    })
  },
  async item (id) {
    return ApiService.get(`/purchase-request/${id}`)
  },
  async create (params) {
    return ApiService.post(`/purchase-request`, params)
  },
  async update (params) {
    return ApiService.put(`/purchase-request/${params.ID}`, params)
  },
  async violated (id, params) {
    return ApiService.put(`/purchase-request/${id}/violated`, params)
  },
  async plannedDateDocumentation (id, params) {
    return ApiService.put(`/purchase-request/${id}/planned-date-documentation`, params)
  },
  async noticeNumber (id, params) {
    return ApiService.put(`/purchase-request/${id}/notice-number`, params)
  },
  async addDocument (id, params) {
    return ApiService.postEncoded(`/purchase-request/${id}/documents`, params)
  },
  async changeDocument (id, docId, params) {
    return ApiService.postEncoded(`/purchase-request/${id}/documents/${docId}`, params)
  },
  async deleteDocument (id, docId) {
    return ApiService.delete(`/purchase-request/${id}/documents/${docId}`)
  },
  async deleteDocumentVersion (id, docId, version) {
    return ApiService.delete(`/purchase-request/${id}/documents/${docId}/version/${version}`)
  },
  async statusHistory (id) {
    return ApiService.get(`/purchase-request/${id}/status/history`)
  },
  async statusChange (id, params) {
    return ApiService.post(`/purchase-request/${id}/status/change`, params)
  },
  async getChatMessages (id, params) {
    return ApiService.query(`/purchase-request/${id}/chat`, {
      params:           params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { encode: false })
      }
    })
  },
  async addChatMessage (id, params) {
    return ApiService.postEncoded(`/purchase-request/${id}/chat`, params)
  },
  async updateChatMessage (id, mid, params) {
    return ApiService.postEncoded(`/purchase-request/${id}/chat/${mid}?_method=put`, params)
  },
  async deleteChatFile (id, mId, fileId) {
    return ApiService.delete(`/purchase-request/${id}/chat/${mId}/${fileId}`)
  },
  async usersSigners (id, params) {
    return ApiService.put(`/purchase-request/${id}/users-signers`, params)
  },
  async eds (id, params) {
    return ApiService.post(`/purchase-request/${id}/eds`, params)
  },
  async SendToApproval (id) {
    return ApiService.post(`/purchase-request/${id}/procurement-documents/send-to-approval`)
  },
  async uploadProcurementDocuments (id, params) {
    return ApiService.postEncoded(`/purchase-request/${id}/procurement-documents`, params)
  },
  async deleteProcurementDocument (id, fileId) {
    return ApiService.delete(`/purchase-request/${id}/procurement-documents/${fileId}`)
  },
  async exportApprovalSheet (id) {
    return ApiService.post(`/purchase-request/${id}/export/approval-sheet`)
  },
  async sendToRevisionProcurementDocument (id, params) {
    return ApiService.post(`/purchase-request/${id}/procurement-documents/send-to-revision`, params)
  },
  async responsibleUOZ (id, params) {
    return ApiService.put(`/purchase-request/${id}/responsible-uoz`, params)
  },
}
export const AgreementService = {
  async list (params) {
    return ApiService.query('/agreement/registry', {
      params:           params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { encode: false })
      }
    })
  },
  async item (id) {
    return ApiService.get(`/agreement/${id}`)
  },
}
export const ListService = {
  list (d) {
    return ApiService.query(`/directory`, {
      params:           d,
      paramsSerializer: function (params) {
        return qs.stringify(params, { encode: false })
      }
    })
  }
}
export const UserService = {
  list (d) {
    return ApiService.query(`/users`, {
      params:           d,
      paramsSerializer: function (params) {
        return qs.stringify(params, { encode: false })
      }
    })
  }
}

export const DirectoryService = {
  search (q) {
    return ApiService.query(`/directory/search`, {
      params:           q,
      paramsSerializer: function (params) {
        return qs.stringify(params, { encode: false })
      }
    })
  }
}

export const PlanService = {
  async list (params) {
    return ApiService.query('/plan/registry', {
      params:           params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { encode: false })
      }
    })
  },
  async item (id) {
    return ApiService.get(`/plan/${id}`)
  },
  async create (params) {
    return ApiService.post(`/plan`, params)
  },
  async update (id, params) {
    return ApiService.put(`/plan/${id}`, params)
  },
  async createPeriod (id, params) {
    return ApiService.post(`/plan/${id}/period-edit`, params)
  },
  async editPeriod (id, params) {
    return ApiService.put(`/plan/period-edit/${id}`, params)
  },
  async deletePeriod (id) {
    return ApiService.delete(`/plan/period-edit/${id}`)
  },
}

export const PlanApproval = {
  async list (params) {
    return ApiService.query('/plan-approval/registry', {
      params: params, paramsSerializer: function (params) {
        return qs.stringify(params, { encode: false })
      }
    })
  },
  async create (params) {
    return ApiService.postEncoded(`/plan-approval`, params)
  },
  async update (id, params) {
    return ApiService.postEncoded(`/plan-approval/${id}`, params)
  },
  async item (id) {
    return ApiService.get(`/plan-approval/${id}`)
  },
  async allDocs (id) {
    return ApiService.post(`/plan-approval/${id}/download-all-documents`)
  },
  async revoke (id) {
    return ApiService.post(`/plan-approval/${id}/revoke`)
  },
  async sign (id, params) {
    return ApiService.post(`/plan-approval/${id}/sign`, params)
  },
  async signList (id) {
    return ApiService.post(`/plan-approval/${id}/download-sign-list`)
  },
}

export default ApiService

export const vClickOutside = {
  mounted(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (el.preventOnceClickOutsideEvent) {
        el.preventOnceClickOutsideEvent = false
        return
      }

        if (!(el === event.target || el.contains(event.target))) {
          if (typeof binding.value === "function") {
            binding.value(event, el)
            return
          }

          if (typeof binding.value === "object") {
            if (binding.value.when === "display") {
              if (getComputedStyle(el).display !== "none") {
                binding.value.call(event, el)
              }
              return
            }

            binding.value.call(event, el)
          }
        }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  updated(el, binding) {
    if (typeof binding.value === "object") {
      if (binding.value.active !== undefined) {
        el.preventOnceClickOutsideEvent = binding.value.active;
      }
    }
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
}

<script setup>
import shared from "@/common/shared";
import Pagination from "@/components/partial/Pagination.vue";
import {computed, ref} from "vue";
import moment from "moment";
import {PlanService, PurchaseService} from "@/common/api.service";
import {useRoute, useRouter} from "vue-router";
import {useToast} from "vue-toastification";

const route = useRoute()
const router = useRouter()
const toast = useToast()
const list = ref({
  columns: [],
  directories: {},
  meta: {
    total: 0,
    per_page: 0,
    total_filtered: 0,
    IS_CAN_EXPORT: false,
    EXPENSES_SUM: 0,
    COST_SUM: 0,
  }
});
const currentPage = ref(1)
const currentLimit = ref(10)
const checked = ref([])
const subLines = ref({})
const request = ref({
  is_initial_load: 1,
  page: currentPage.value,
  limit: currentLimit.value,
  filters: {
    UF_NUMBER: "",
    UF_SUBJECT: "",
    IS_ARCHIVE: "",
    UF_DATE_SIGN_FROM: "",
    UF_DATE_SIGN_TO: ""
  }
})
const reqHistory = ref([])
const currentPlan = ref({})
const reportData = ref({})
const createPlanData = ref({})
const createPlanDates = ref({
  maxDate: Date.now(),
  minDate: Date.now()
})
const editPlanPeriodData = ref({})
const dbl = ref(0)

if (route.query._r) {
  request.value = JSON.parse(route.query._r)
  currentPage.value = request.value.page
  currentLimit.value = request.value.limit
}

async function fetchList() {
  await PlanService.list(request.value)
      .then(({data}) => {
        list.value = data
        reportData.value.ID = list.value.meta.REPORT_LIST[0].ID
        if (data.order) {
          request.value.order = data.order
        }
        request.value.filters = data.filters
        request.value.order_empty = 1
        delete request.value.is_initial_load
        router.push({
          query: {
            _r: JSON.stringify(request.value)
          }
        })
      }).catch(() => {
      })
}

await fetchList()

const columnList = computed(() => {
  return list.value.columns.filter((el) => el.visible)
})
const checkAll = computed({
  get: function () {
    return list.value.data ? list.value.length === checked.value.length : false;
  },
  set: function (value) {
    let ch = []

    if (value) {
      list.value.data.forEach(function (item) {
        ch.push(item.ID);
      });
    }

    checked.value = ch;
  }
})

async function changeOrder(f) {
  if (!request.value.order[f]) {
    request.value.order[f] = 'asc'
  } else if (request.value.order[f] === 'asc') {
    request.value.order[f] = 'desc'
  } else {
    delete request.value.order[f]
  }
  await fetchList()
}

function showedSub(i) {
  if (!subLines.value[i]) {
    subLines.value[i] = false
  }
  return subLines.value[i]
}

function showHistory(items) {
  reqHistory.value = items
  shared.showModal("#modal-history-plan-year")
}

function showPlan(item) {
  currentPlan.value = item
  createPlanData.value = {
    UF_HEAD_ACCOUNTANT_ID: item.HEAD_ACCOUNTANT.ID
  }
  shared.showModal("#modal-current-year-purchase")
}

async function editPlan() {
  await PlanService.update(currentPlan.value.ID, {
    UF_HEAD_ACCOUNTANT_ID: createPlanData.value.UF_HEAD_ACCOUNTANT_ID
  })
      .then(async () => {
        shared.closeModal('#modal-current-year-purchase')
        await fetchList()
      }).catch(({response}) => {
        toast.error(response.data.message, {
          timeout: 5000
        });
      })
}

function makeFile(item) {
  reportData.value.UF_PLAN_ID = item.ID
  reportData.value.UF_YEAR = item.UF_YEAR
  shared.showModal("#make-file")
}

async function exportExcel() {
  await PurchaseService.report(reportData.value.ID, reportData.value)
      .then(async ({data}) => {
        document.location.href = data.url
        shared.closeModal('#make-file')
      }).catch(({response}) => {
        toast.error(response.data.message, {
          timeout: 5000
        });
      })
}

function openCreatePlan() {
  createPlanData.value = {
    UF_HEAD_ACCOUNTANT_ID: list.value.meta.DEFAULT_SETTINGS.UF_HEAD_ACCOUNTANT_DEF_ID
  }
  shared.showModal("#modal-add-plan-purchase")
}

function setPlanTime() {
  if (createPlanData.value.UF_YEAR > 0) {
    createPlanData.value.UF_PLAN_DATE_START = "01.01." + createPlanData.value.UF_YEAR
    createPlanData.value.UF_PLAN_DATE_END = "31.12." + createPlanData.value.UF_YEAR
    createPlanDates.value.maxDate = moment(`31.12.${createPlanData.value.UF_YEAR}`, "DD.MM.YYYY").toDate()
    createPlanDates.value.minDate = moment(`01.01.${createPlanData.value.UF_YEAR}`, "DD.MM.YYYY").toDate()
    createPlanDates.value.dates = [createPlanData.value.UF_PLAN_DATE_START, createPlanData.value.UF_PLAN_DATE_END]
  } else {
    createPlanData.value.UF_PLAN_DATE_START = ""
    createPlanData.value.UF_PLAN_DATE_END = ""
    createPlanDates.value.maxDate = Date.now()
    createPlanDates.value.minDate = Date.now()
    createPlanDates.value.dates = []
  }
}

async function createPlan() {
  createPlanData.value.UF_PLAN_DATE_START = createPlanDates.value.dates[0]
  createPlanData.value.UF_PLAN_DATE_END = createPlanDates.value.dates[1]
  await PlanService.create(createPlanData.value)
      .then(async () => {
        shared.closeModal('#modal-add-plan-purchase')
        await fetchList()
        dbl.value++
      }).catch(({response}) => {
        toast.error(response.data.message, {
          timeout: 5000
        });
        dbl.value++
      })
}

function openCreatePlanPeriod(id) {
  editPlanPeriodData.value = {
    ID: id,
    UF_DATE_EDIT_PLAN_START: null,
    UF_DATE_EDIT_PLAN_END: null
  }
  shared.showModal("#modal-add-period-purchase")
}

async function createPlanPeriod() {
  await PlanService.createPeriod(editPlanPeriodData.value.ID, {
    UF_DATE_EDIT_PLAN_START: moment.utc(editPlanPeriodData.value.UF_DATE_EDIT_PLAN_START, "DD.MM.YYYY").toISOString(),
    UF_DATE_EDIT_PLAN_END: moment.utc(editPlanPeriodData.value.UF_DATE_EDIT_PLAN_END, "DD.MM.YYYY").toISOString()
  })
      .then(async () => {
        shared.closeModal('#modal-add-period-purchase')
        await fetchList()
        dbl.value++
      }).catch(({response}) => {
        toast.error(response.data.message, {
          timeout: 5000
        });
        dbl.value++
      })
}

function openEditPlanPeriod(id, i) {
  editPlanPeriodData.value = {
    ID: id,
    UF_DATE_EDIT_PLAN_START: moment(i.UF_DATE_EDIT_PLAN_START).format("DD.MM.YYYY"),
    UF_DATE_EDIT_PLAN_END: moment(i.UF_DATE_EDIT_PLAN_END).format("DD.MM.YYYY")
  }
  shared.showModal("#modal-edit-period-purchase")
}

async function editPlanPeriod() {
  await PlanService.editPeriod(editPlanPeriodData.value.ID, {
    UF_DATE_EDIT_PLAN_START: moment.utc(editPlanPeriodData.value.UF_DATE_EDIT_PLAN_START, "DD.MM.YYYY").toISOString(),
    UF_DATE_EDIT_PLAN_END: moment.utc(editPlanPeriodData.value.UF_DATE_EDIT_PLAN_END, "DD.MM.YYYY").toISOString()
  })
      .then(async () => {
        shared.closeModal('#modal-edit-period-purchase')
        await fetchList()
      }).catch(({response}) => {
        toast.error(response.data.message, {
          timeout: 5000
        });
      })
}

async function deletePlanPeriod() {
  await PlanService.deletePeriod(editPlanPeriodData.value.ID)
      .then(async () => {
        shared.closeModal('#modal-edit-period-purchase')
        await fetchList()
      }).catch(({response}) => {
        toast.error(response.data.message, {
          timeout: 5000
        });
      })
}

document.addEventListener('DOMContentLoaded', function () {
  function applyDateMask(inputField, isFullFormat) {
    inputField.addEventListener('input', function () {
      let value = inputField.value.replace(/\D/g, '');
      let formattedValue = formatInput(value, isFullFormat);

      inputField.value = formattedValue;
    });
  }

  function formatInput(value, isFullFormat) {
    let formattedValue = '';
    if (value.length > 0) {
      formattedValue += value.substring(0, Math.min(2, value.length)) + '.';
    }
    if (value.length > 2) {
      formattedValue += value.substring(2, Math.min(4, value.length)) + '.';
    }
    if (value.length > 4) {
      formattedValue += value.substring(4, Math.min(8, value.length));
    }
    if (isFullFormat && value.length > 8) {
      formattedValue += ' - ' + value.substring(8, Math.min(10, value.length)) + '.';
    }
    if (isFullFormat && value.length > 10) {
      formattedValue += value.substring(10, Math.min(12, value.length)) + '.';
    }
    if (isFullFormat && value.length > 12) {
      formattedValue += value.substring(12, Math.min(16, value.length));
    }
    return formattedValue;
  }

  let inputMaskFields = document.querySelectorAll('.js-mask-data');

  inputMaskFields.forEach(function (inputField) {
    if (inputField.classList.contains('js-mask-data-first')) {
      applyDateMask(inputField, false);
    } else {
      applyDateMask(inputField, true);
    }
  });
});
</script>

<template>
  <div class="main-wraper" id="js-main-wraper">
    <MainHeader></MainHeader>
    <section class="content-wrapper" id="js-content-wrapper">
      <div class="container-fluid">
        <section class="page-title-container">
          <div class="page-title-container__col">
            <div class="page-breadcrumbs"></div>
            <div class="page-title --st-18">Администрирование Плана закупки</div>
          </div>
          <div class="page-title-container__col page-title-container__controll">
            <button type="button" @click="openCreatePlan" class="btn btn-blue">
              Добавить план закупки
            </button>
          </div>
        </section>
        <section class="page-container">
          <div class="box-stl c-table--container">
            <div class="holder-responsive">
              <table class="c-table c-table-administration">
                <thead class="thead-bold">
                <tr>
                  <th :key="column.name" v-for="column in columnList">
                    <div class="sorting-holder" v-if="column.type==='column'">
                      <span>{{ column.title }}</span>
                      <span @click="changeOrder(column.name)" v-if="column.orderable"
                            class="sorting-link" :class="{
                                            'active': list.order[column.name] === 'desc',
                                            'active-up': list.order[column.name] === 'asc'
                                        }"></span>
                    </div>
                    <div class="sorting-holder" v-if="column.type==='select'">
                      <input type="checkbox" v-model="checkAll">
                    </div>
                  </th>
                </tr>
                </thead>
                <tbody :key="item.ID" v-for="item in list.data" class="tr-bottom-line" :class="{
                  'c-table-history-container': item.children && item.children.length > 1,
                  'active': showedSub(item.ID)
                  }">
                <tr :class="{
                  'c-table-row_active text-bold': item.children && item.children.length > 1
                  }">
                  <td :key="column.name" v-for="column in columnList">
                    <button v-if="column.display_as === 'custom'
                    && column.display_as_config.custom === 'export'" @click="makeFile(item)" type="button"
                            class="btn btn-table btn-blue">сформировать
                    </button>

                    <a v-if="column.display_as === 'router_link'" @click="showPlan(item)"
                       class="c-link-blue stl-text-line--none">{{ shared.showField(column, item) }}</a>

                    <div
                        v-if="column.display_as === 'custom' && column.display_as_config.custom === 'periods-filling' && item.PERIODS_FILLING.length > 0"
                        class="data-period-holder">
                      <div class="data-period-holder__data">
                        <a @click="openEditPlanPeriod(i.ID, i)" v-for="i in item.PERIODS_FILLING" :key="i.ID"
                           class="data-period-holder__data--item c-link-blue"
                           >с {{ moment(i.UF_DATE_EDIT_PLAN_START).format("DD.MM.YYYY") }}
                          по
                          {{ moment(i.UF_DATE_EDIT_PLAN_END).format("DD.MM.YYYY") }}
                        </a>
                      </div>
                      <a @click="openCreatePlanPeriod(item.ID)"
                         v-if="item.UF_IS_VIEW_BUTTON_ADD_PERIOD_FILLING"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                              d="M9.25 10.75V14C9.25 14.2125 9.32192 14.3906 9.46575 14.5343C9.60958 14.6781 9.78775 14.75 10.0003 14.75C10.2129 14.75 10.391 14.6781 10.5345 14.5343C10.6782 14.3906 10.75 14.2125 10.75 14V10.75H14C14.2125 10.75 14.3906 10.6781 14.5343 10.5343C14.6781 10.3904 14.75 10.2122 14.75 9.99975C14.75 9.78708 14.6781 9.609 14.5343 9.4655C14.3906 9.32183 14.2125 9.25 14 9.25H10.75V6C10.75 5.7875 10.6781 5.60942 10.5343 5.46575C10.3904 5.32192 10.2122 5.25 9.99975 5.25C9.78708 5.25 9.609 5.32192 9.4655 5.46575C9.32183 5.60942 9.25 5.7875 9.25 6V9.25H6C5.7875 9.25 5.60942 9.32192 5.46575 9.46575C5.32192 9.60958 5.25 9.78775 5.25 10.0003C5.25 10.2129 5.32192 10.391 5.46575 10.5345C5.60942 10.6782 5.7875 10.75 6 10.75H9.25ZM10.0017 19.5C8.68775 19.5 7.45267 19.2507 6.2965 18.752C5.14033 18.2533 4.13467 17.5766 3.2795 16.7218C2.42433 15.8669 1.74725 14.8617 1.24825 13.706C0.749417 12.5503 0.5 11.3156 0.5 10.0017C0.5 8.68775 0.749333 7.45267 1.248 6.2965C1.74667 5.14033 2.42342 4.13467 3.27825 3.2795C4.13308 2.42433 5.13833 1.74725 6.294 1.24825C7.44967 0.749417 8.68442 0.5 9.99825 0.5C11.3123 0.5 12.5473 0.749333 13.7035 1.248C14.8597 1.74667 15.8653 2.42342 16.7205 3.27825C17.5757 4.13308 18.2528 5.13833 18.7518 6.294C19.2506 7.44967 19.5 8.68442 19.5 9.99825C19.5 11.3123 19.2507 12.5473 18.752 13.7035C18.2533 14.8597 17.5766 15.8653 16.7218 16.7205C15.8669 17.5757 14.8617 18.2528 13.706 18.7518C12.5503 19.2506 11.3156 19.5 10.0017 19.5ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
                              fill="#0D74BB"/>
                        </svg>
                      </a>
                    </div>
                    <button type="button"
                            v-if="column.display_as === 'custom' && column.display_as_config.custom === 'periods-filling' &&
                            item.UF_IS_VIEW_BUTTON_ADD_PERIOD_FILLING
                            && item.PERIODS_FILLING.length === 0"
                            class="btn btn-table btn-full btn-blue"
                            @click="openCreatePlanPeriod(item.ID)"
                    >добавить период
                    </button>

                    <a v-if="column.display_as === 'custom'
                     && column.display_as_config.custom === 'periods-logs'
                     && item[column.name].length > 0" @click="showHistory(item[column.name])"
                       class="c-i-icon c-i-time"></a>
                    <span v-if="column.type === 'icon' && item[column.key]" class="d-i-icon"
                          :class="item[column.key].type" :title="item[column.key].title"></span>
                    <span
                        :class="{'active': showedSub(item.ID)}"
                        @click="showSub(item.ID)"
                        v-if="column.type==='expand_children' && item.children && item.children.length > 1"
                        class="js-link-chevron icon-chevron"></span>
                    <span v-if="column.display_as === 'text' || column.display_as === 'text_path'"
                          :style="{
                      'white-space': column.modifiers.find(val => val === 'white-space-pre')?'pre':'',
                      'word-break': column.modifiers.find(val => val === 'break-all')?'normal':''
                    }"
                    >{{
                        shared.showField(column, item)
                      }}</span>
                    <div class="sorting-holder" v-if="column.type==='select'">
                      <input type="checkbox" :value="item.ID" v-model="checked">
                    </div>
                  </td>
                </tr>
                <tr class="c-table-row_hidden" :key="i2.ID" v-for="i2 in item.children"
                    :class="{'active': showedSub(item.ID)}">
                  <td :key="column.name" v-for="column in columnList">
                    <span v-if="column.display_as === 'text' || column.display_as === 'text_path'"
                          :class="{'stl-ws-pre':column.modifiers.filter(val => val === 'white-space-pre')}">{{
                        shared.showField(column, i2)
                      }}</span>
                  </td>
                </tr>
                </tbody>
                <tbody>
                <tr v-if="list.meta.IS_CAN_BULK_STATUS_CHANGE || list.meta.IS_CAN_BULK_APPROVAL">
                  <td colspan="10">
                    <button v-if="list.meta.IS_CAN_BULK_STATUS_CHANGE" type="button" class="btn btn-blue c-btn-table"
                            data-bs-toggle="modal"
                            :disabled="checked.length === 0"
                            data-bs-target="#modal-edit-status">сменить статус
                    </button>
                    <button v-if="list.meta.IS_CAN_BULK_APPROVAL" type="button" class="btn btn-blue c-btn-table"
                            data-bs-toggle="modal"
                            :disabled="checked.length === 0"
                            data-bs-target="#modal-approve">согласовать
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
              <Pagination
                  :pages="pages"
                  v-model="currentPage"
                  :total="list.meta.total_filtered"
                  :perPage="currentLimit"
                  @update:perPage="currentLimit = $event"
              ></Pagination>
            </div>
          </div>
        </section>
      </div>
    </section>
    <MainFooter></MainFooter>
  </div>

  <!-- Добавить План закупки -->
  <div class="modal" id="modal-add-plan-purchase" tabindex="-1">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Добавить План закупки</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#modal-add-plan-purchase')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Год</label>
              <select @change="setPlanTime" v-model="createPlanData.UF_YEAR" class="c-input --black c-select c-select--disabled">
                <option value="">Выбрать из списка</option>
                <option :value="i"
                        :key="i"
                        :disabled="list.data.find(val => val.UF_YEAR === i)"
                        v-for="i in shared.yearList(2023, moment().toDate().getFullYear()+5)">
                  {{ i }}
                </option>
              </select>
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Период планирования</label>
              <VueDatePicker
                  placeholder="00.00.0000 - 00.00.0000"
                  class="c-filter__item-data-inp c-filter__item-full"
                  v-model="createPlanDates.dates" :min-date="createPlanDates.minDate"
                  :max-date="createPlanDates.maxDate" format="dd.MM.yyyy"
                  model-type="format" range :show-last-in-range="false"/>
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Главный бухгалтер </label>
              <select v-model="createPlanData.UF_HEAD_ACCOUNTANT_ID" class="c-input --black c-select">
                <option :key="i" v-for="i in list.meta.USERS" :value="i.ID">{{ i.FULL_NAME }}</option>
              </select>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#modal-add-plan-purchase')">отменить
          </button>
          <button type="button" class="btn btn-blue" :key="dbl" @click="createPlan()">добавить</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Добавить План закупки -->

  <!-- Добавить период -->
  <div class="modal" id="modal-add-period-purchase" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Период заполнения Плана закупки</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#modal-add-period-purchase')" aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Дата начала внесения изменений</label>
              <VueDatePicker
                  placeholder="00.00.0000"
                  class="c-filter__item-data-inp c-filter__item-full"
                  v-model="editPlanPeriodData.UF_DATE_EDIT_PLAN_START" format="dd.MM.yyyy"
                  model-type="format"/>
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Дата окончания внесения изменений</label>
              <VueDatePicker
                  placeholder="00.00.0000"
                  class="c-filter__item-data-inp c-filter__item-full"
                  v-model="editPlanPeriodData.UF_DATE_EDIT_PLAN_END" format="dd.MM.yyyy"
                  model-type="format"/>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#modal-add-period-purchase')">отменить</button>
          <button type="button" class="btn btn-blue" :key="dbl" @click.once="createPlanPeriod">добавить</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Добавить период -->

  <!-- Изменить период -->
  <div class="modal" id="modal-edit-period-purchase" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Период заполнения Плана закупки</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#modal-edit-period-purchase')" aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Дата начала внесения изменений</label>
              <VueDatePicker
                  placeholder="00.00.0000"
                  class="c-filter__item-data-inp c-filter__item-full"
                  v-model="editPlanPeriodData.UF_DATE_EDIT_PLAN_START" format="dd.MM.yyyy"
                  model-type="format"/>
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Дата окончания внесения изменений</label>
              <VueDatePicker
                  placeholder="00.00.0000"
                  class="c-filter__item-data-inp c-filter__item-full"
                  v-model="editPlanPeriodData.UF_DATE_EDIT_PLAN_END" format="dd.MM.yyyy"
                  model-type="format"/>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#modal-edit-period-purchase')">отменить</button>
          <button type="button" class="btn btn-red" @click="deletePlanPeriod">удалить</button>
          <button type="button" class="btn btn-blue" @click="editPlanPeriod">изменить</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Изменить период -->

  <!-- План закупки {{YEAR}} год  -->
  <div class="modal" id="modal-current-year-purchase" tabindex="-1">
    <div class="modal-dialog modal-md  modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ currentPlan.UF_NAME }}</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#modal-current-year-purchase')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Год</label>
              <select v-model="currentPlan.UF_YEAR" name="" id="" disabled
                      class="c-input --black c-select disabled-light">
                <option :value="currentPlan.UF_YEAR">{{ currentPlan.UF_YEAR }}</option>
              </select>
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Период планирования</label>
              <input type="text" disabled class="c-input --black disabled-light c-input-data js-mask-data"
                     v-model="currentPlan.PERIOD" placeholder="00.00.0000 - 00.00.0000">
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Главный бухгалтер </label>
              <select v-model="createPlanData.UF_HEAD_ACCOUNTANT_ID" class="c-input --black c-select">
                <option :key="i" v-for="i in list.meta.USERS" :value="i.ID">{{ i.FULL_NAME }}</option>
              </select>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#modal-current-year-purchase')">
            отменить
          </button>
          <button type="button" class="btn btn-blue" @click="editPlan">изменить</button>
        </div>
      </div>
    </div>
  </div>
  <!-- План закупки {{YEAR}} год  -->

  <!-- История изменений Плана закупки {{year}} года -->
  <div class="modal" id="modal-history-plan-year" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">История изменений Плана закупки 2023 года</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"
                  @click="shared.closeModal('#modal-history-plan-year')"></button>
        </div>
        <div class="modal-body" style="margin-bottom: 25px;">
          <table class="card-table">
            <thead>
            <tr>
              <th>Дата</th>
              <th>ФИО пользователя</th>
              <th>Изменения</th>
            </tr>
            </thead>
            <tbody class="tr-bottom-line">
            <tr :key="item.ID" v-for="item in reqHistory">
              <td>{{
                  moment(item.UF_DATE_CREATE).format("DD.MM.YYYY HH:mm")
                }}
              </td>
              <td>{{ item.CREATED_BY.FULL_NAME }}</td>
              <td>{{ item.UF_TEXT_CHANGE }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" data-bs-dismiss="modal"
                  @click="shared.closeModal('#modal-history-plan-year')">закрыть
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="make-file" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Сформировать файл</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#make-file')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Выбор отчета <span class="c-c-danger">*</span></label>
              <select v-model="reportData.ID" class="c-input c-select">
                <option :value="i.ID"
                        :key="i.ID" v-for="i in list.meta.REPORT_LIST">
                  {{ i.UF_NAME }}
                </option>
              </select>
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Формат выгрузки <span class="c-c-danger">*</span></label>
              <select v-model="reportData.FORMAT" class="c-input c-select">
                <option :value="type"
                        :key="type"
                        v-for="(i, type) in list.meta.REPORT_LIST.find((el) => el.ID === reportData.ID).FORMATS">
                  {{ i }}
                </option>
              </select>
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">План <span class="c-c-danger">*</span></label>
              <select @change="reportData.UF_PLAN_ID= list.data.find(el => el.ID === reportData.UF_PLAN_ID).ID"
                      v-model="reportData.UF_PLAN_ID" class="c-input c-select">
                <option :value="i.ID"
                        :key="i.ID"
                        v-for="i in list.data">
                  {{ i.UF_YEAR }}
                </option>
              </select>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#make-file')">отменить
          </button>
          <button type="button" class="btn btn-blue" @click="exportExcel(1)">
            Сформировать
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- История изменений Плана закупки 2024 года -->
</template>

<style scoped>

</style>

<template>
  <ul class="chat-navigation-list">
    <li v-show="modelValue > page - 5 && modelValue < page + 5"
        v-for="page in pages"
        :key="page"
        :class="paginationClass(page)"
        @click.prevent="changePage(page)">{{ page }}</li>
  </ul>
</template>

<script>
export default {
  name: "ChatPagination",
  props: {
    pages: {
      type: Array,
      required: true
    },
    modelValue: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      itemsPerPage: this.perPage,
    };
  },
  methods: {
    changePage(goToPage) {
      if (goToPage === this.modelValue) return;
      this.$emit("update:modelValue", goToPage);
    },
    paginationClass(page) {
      return {
        "chat-navigation-list__item": true,
        active: this.modelValue === page
      };
    },
    changeItemsPerPage(items) {
      if (items === this.perPage) return;
      this.$emit("update:perPage", items);
    },
  }
}
</script>

<style scoped>

</style>
<script setup async>
import {onMounted, onUnmounted} from "vue";

function FooterFixedResize() {
    let footer = document.getElementById("footer");
    let footerHeight = document.getElementById("footer").clientHeight;
    let contentWrapper = document.getElementById("js-content-wrapper");
    footer.classList.add("fixed");
    contentWrapper.style.paddingBottom = `${footerHeight}px`;
}

function optionSize() {
  let modalOptions = document.querySelectorAll('#modal-purchase-plan select option');

  modalOptions.forEach(function(option) {
    if (option.textContent.length > 140) {
      option.textContent = option.textContent.substring(0, 140) + '...';
    }
  });
}

window.addEventListener("resize", () => {
    FooterFixedResize()
});

onMounted(async () => {
    FooterFixedResize()
    optionSize()
})

onUnmounted(() => {
    window.removeEventListener("resize", FooterFixedResize);
})
</script>
<template>
    <footer class="footer" id="footer">
        <div class="footer-area">
            <div class="footer-left">
                <div class="footer-logo"></div>
                <slot name="left-control"></slot>
            </div>

            <div class="footer-right">
                <slot></slot>
            </div>
        </div>
    </footer>
</template>

export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_TOKEN = "setToken";
export const SET_ROLE = "setUserRole";
export const SET_FULL_ROLE = "setFullUserRole";
export const SET_ERROR = "setError";
export const UPDATE_PROJECT_IN_LIST = "updateProjectInList";
export const FETCH_PROJECTS_START = "setLoading";
export const FETCH_PROJECTS_END = "setProjects";
export const FETCH_PROJECTS_ERROR = "setProjectsError";
export const ADD_PROJECT_START = "setAddProjectLoading";
export const ADD_PROJECT_END = "endAddProjectLoading";
export const ADD_PROJECT_ERROR = "setAddProjectError";
export const SAVE_PROJECT_START = "setSaveProjectLoading";
export const SAVE_PROJECT_END = "endSaveProjectLoading";
export const SAVE_PROJECT_ERROR = "setSaveProjectError";
export const SET_PROJECT = "setProject";
export const FETCH_PROJECT_START = "setLoading";
export const FETCH_PROJECT_END = "setProject";
export const FETCH_DIRECTORY_END = "setDirectory";
export const FETCH_EXPERTISES_START = "setExpLoading";
export const FETCH_EXPERTISES_END = "setExpertises";
export const FETCH_ALL_EXPERTISES_START = "setAllExpLoading";
export const FETCH_ALL_EXPERTISES_END = "setAllExpertises";
export const FETCH_EXPERTS_START = "setExpsLoading";
export const FETCH_EXPERTS_END = "setExperts";
export const FETCH_EA_START = "setEALoading";
export const FETCH_EA_END = "setEA";
export const FETCH_GROUP_ORGS_START = "setGroupOrgsLoading";
export const FETCH_GROUP_ORGS_END = "setGroupOrgs";
export const FETCH_MONITORING_LIZING_ORGS_START =
  "setMonitoringLizingOrgsLoading";
export const FETCH_MONITORING_LIZING_ORGS_END = "setMonitoringLizingOrgs";
export const FETCH_GROUP_ORGS_LIZING_START = "setGroupOrgsLizingLoading";
export const FETCH_GROUP_ORGS_LIZING_END = "setGroupOrgsLizing";
export const FETCH_REQUESTS_START = "setRequestsLoading";
export const FETCH_REQUESTS_END = "setRequestsEnd";
export const FETCH_REQUEST_START = "setRequestLoading";
export const FETCH_REQUEST_END = "setRequestEnd";
export const FETCH_REQUEST_CHAT_START = "setRequestChatLoading";
export const FETCH_REQUEST_CHAT_END = "setRequestChatEnd";
export const FETCH_ADMIN_LIST_START = "setAdminListStart";
export const FETCH_ADMIN_LIST_END = "setAdminListEnd";

<script setup async>
import Pagination from "@/components/partial/Pagination.vue";
import {onMounted, ref, computed, watch, onUnmounted} from "vue";
import {AgreementService, DirectoryService} from "@/common/api.service";
import {useRoute, useRouter} from 'vue-router';
import shared from "@/common/shared";

const route = useRoute()
const router = useRouter()
const list = ref({
  columns: [],
  directories: {},
  meta: {
    total: 0,
    per_page: 0,
    total_filtered: 0,
    IS_CAN_EXPORT: false,
    EXPENSES_SUM: 0,
    COST_SUM: 0,
  }
});
const currentPage = ref(1)
const currentLimit = ref(10)
const request = ref({
  is_initial_load: 1,
  page: currentPage.value,
  limit: currentLimit.value,
  filters: {
    UF_NUMBER: "",
    UF_SUBJECT: "",
    IS_ARCHIVE: "",
    UF_DATE_SIGN_FROM: "",
    UF_DATE_SIGN_TO: ""
  }
})
const checked = ref([])
const subLines = ref({})
const isLoading = ref(false)
const counterparty = ref([])

if (route.query._r) {
  request.value = JSON.parse(route.query._r)
  currentPage.value = request.value.page
  currentLimit.value = request.value.limit
}

async function fetchList() {
  await AgreementService.list(request.value)
      .then(({data}) => {
        if (request.value.is_initial_load && data.meta.COUNTERPARTY_LIST) {
          data.meta.COUNTERPARTY_LIST.forEach(function (f) {
            counterparty.value.push({value: f.ID, label: `${f.UF_NAME}`})
          })
        }
        list.value = data
        if (data.order) {
          request.value.order = data.order
        }
        checked.value = []
        request.value.filters = data.filters
        if (!request.value.filters.UF_NUMBER) {
          request.value.filters.UF_NUMBER = ""
        }
        if (!request.value.filters.UF_SUBJECT) {
          request.value.filters.UF_SUBJECT = ""
        }
        if (!request.value.filters.IS_ARCHIVE) {
          request.value.filters.IS_ARCHIVE = ""
        }
        if (!request.value.filters.UF_DATE_SIGN_TO) {
          request.value.filters.UF_DATE_SIGN_TO = ""
        }
        if (!request.value.filters.UF_DATE_SIGN_FROM) {
          request.value.filters.UF_DATE_SIGN_FROM = ""
        }
        request.value.order_empty = 1
        delete request.value.is_initial_load
        router.push({
          query: {
            _r: JSON.stringify(request.value)
          }
        })
      }).catch(() => {
      })
}

await fetchList()

const counterpartyList = computed(() => {
  let r = []
  counterparty.value.forEach(function (f) {
    r.push({value: f.ID, label: `${f.UF_NAME}`})
  })

  return r
})
const columnList = computed(() => {
  return list.value.columns.filter((el) => el.visible)
})
const pages = computed(() => {
  if (
      !list.value.meta.total_filtered ||
      list.value.meta.total_filtered <= list.value.meta.per_page
  ) {
    return [];
  }
  return [
    ...Array(Math.ceil(list.value.meta.total_filtered / list.value.meta.per_page)).keys()
  ].map(e => e + 1);
})
const checkAll = computed({
  get: function () {
    return list.value.data ? list.value.length === checked.value.length : false;
  },
  set: function (value) {
    let ch = []

    if (value) {
      list.value.data.forEach(function (item) {
        ch.push(item.ID);
      });
    }

    checked.value = ch;
  }
})

watch(currentPage, async () => {
  request.value.page = currentPage.value
  await fetchList()
})

watch(currentLimit, async () => {
  request.value.limit = currentLimit.value
  await fetchList()
})

async function changeOrder(f) {
  if (!request.value.order[f]) {
    request.value.order[f] = 'asc'
  } else if (request.value.order[f] === 'asc') {
    request.value.order[f] = 'desc'
  } else {
    delete request.value.order[f]
  }
  await fetchList()
}

function field(f) {
  let r = list.value.columns.find(el => el.name === f)
  if (r) {
    return r
  }
  return {}
}

async function resetFilter() {
  request.value.filters = {}
  await fetchList()
}

function showedSub(i) {
  if (!subLines.value[i]) {
    subLines.value[i] = false
  }
  return subLines.value[i]
}

function showSub(i) {
  if (!showedSub(i)) {
    subLines.value[i] = true
  } else {
    subLines.value[i] = false
  }
}

function directorySearch(directory, query, dr) {
  isLoading.value = true
  DirectoryService.search({name: directory, q: query}).then(({data}) => {
    dr.value = data
    isLoading.value = false
  })
}

function counterpartySearch(query) {
  directorySearch('agreement_counterparties', query, counterparty)
}

function handleMultiselectTagClick() {
  document.addEventListener('click', function (event) {
    const multiselectTags = document.querySelectorAll('.multiselect-tags');

    multiselectTags.forEach(function (tag) {
      if (tag !== event.target.closest('.multiselect-tags')) {
        tag.classList.remove('show');
      }
    });

    const parent = event.target.closest('.multiselect-tags');
    if (parent) {
      parent.classList.toggle('show');
    }
  });
}

function selectPlaceholderColor() {

  let selects = document.querySelectorAll('select.c-select');

  selects.forEach(select => {
    // Добавляем слушатель события изменения значения
    select.addEventListener('change', function () {

      if (this.value === '') {
        this.style.color = '';
      } else {
        this.style.color = '#6C6D70';
      }
    });
  })
}


onMounted(async () => {
  await router.isReady()
  handleMultiselectTagClick()
  selectPlaceholderColor()
})
onUnmounted(() => {
  handleMultiselectTagClick()
  selectPlaceholderColor()
})
</script>

<template>
  <div class="main-wraper" id="js-main-wraper">
    <MainHeader></MainHeader>
    <!-- header -->
    <section class="content-wrapper" id="js-content-wrapper">
      <div class="container-fluid">
        <section class="page-title-container">
          <div class="page-title-container__col">
            <div class="page-breadcrumbs"><span class="stl-fw-600">Договоры</span></div>
            <div class="page-title-aside df-ac" v-if="list.meta.IS_VIEW_COST_SUM">
                            <span class="page-title-aside__item" v-if="list.meta.EXPENSES_SUM!==''">
                                <span class="stl-fw-600">Сумма (НМЦ) по году, руб.</span>: {{
                                list.meta.EXPENSES_SUM
                              }} </span>
              <span class="page-title-aside__item"
                    v-if="list.meta.COST_SUM!==''">
                                <span class="stl-fw-600">Сумма (НМЦД) по договору, руб.</span>: {{
                  list.meta.COST_SUM
                }} </span>
            </div>
          </div>
          <div class="page-title-container__col page-title-container__controll"
               v-if="list.meta.IS_CAN_EXPORT">
            <a @click="shared.showModal('#make-file')" class="btn btn-blue">Сформировать файл</a>
          </div>
        </section>
        <!-- page-title-container -->
        <section class="box-stl c-filter--container">
          <div class="text-center" id="js-filter-show"><span class="c-filter--mob-link stl-tt-up"
                                                             onclick="filterMobileShow();">Развернуть фильтр</span>
          </div>
          <div class="c-filter--grid df-jsb df-none-wrap df-al" id="js-filter-grid">
            <div class="c-filter--filter-area">
              <div class="c-filter__item" v-if="field('COUNTERPARTY').searchable">
                <Multiselect
                    class="c-input c-select c-filter__input c-multiselect-filter"
                    v-model="request.filters.COUNTERPARTY"
                    mode="single"
                    :loading="isLoading"
                    @search-change="counterpartySearch"
                    :show-no-results="false"
                    :searchable="true"
                    :options="counterpartyList"
                    placeholder="Контрагент"
                    :no-options-text="'Введите название контрагента'"
                />
              </div>
              <div class="c-filter__item" v-if="field('UF_NUMBER').searchable">
                <input type="text" class="c-input c-filter__input" v-model="request.filters.UF_NUMBER"
                       placeholder="Номер договора">
              </div>
              <div class="c-filter__item" v-if="field('UF_SUBJECT').searchable">
                <input type="text" class="c-input c-filter__input" v-model="request.filters.UF_SUBJECT"
                       placeholder="Предмет договора">
              </div>
              <div class="c-filter__item c-filter__item-lg c-filter__item-data-inp c-filter__item-data-inp-small"
                   v-if="field('UF_DATE_SIGN_FROM').searchable">
                <label for="" class="stl-fw-bold filter-label">Дата заключения с</label>
                <VueDatePicker v-model="request.filters.UF_DATE_SIGN_FROM" format="dd.MM.yyyy"
                               model-type="format"
                               :placeholder="''">
                </VueDatePicker>
              </div>
              <div class="c-filter__item c-filter__item-data-inp c-filter__item-data-inp-small"
                   v-if="field('UF_DATE_SIGN_FROM').searchable">
                <label for="" class="stl-fw-bold filter-label">по</label>
                <VueDatePicker v-model="request.filters.UF_DATE_SIGN_TO" format="dd.MM.yyyy"
                               model-type="format"
                               :placeholder="''">
                </VueDatePicker>
              </div>
              <div class="c-filter__item" v-if="field('IS_ARCHIVE').searchable">
              <select v-model="request.filters.IS_ARCHIVE"
                      class="c-input c-select c-filter__input">
                <option value="">Действующий</option>
                <option :value="1" >Да</option>
                <option :value="0" >Нет</option>
              </select>
            </div>
              <div class="c-filter__controll">
                <div class="btn btn-reset" @click="resetFilter">СБРОС</div>
                <div class="btn btn-blue" @click="fetchList">Применить</div>
              </div>
            </div>
          </div>
          <!-- c-filter--grid -->
        </section>
        <!-- c-filter--container -->
        <section class="page-container">
          <div class="box-stl c-table--container">
            <div class="holder-responsive">
              <table class="c-table">
                <thead>
                <tr>
                  <th :key="column.name" v-for="column in columnList">
                    <div class="sorting-holder" v-if="column.type==='column'">
                      <span>{{ column.title }}</span>
                      <span @click="changeOrder(column.name)" v-if="column.orderable"
                            class="sorting-link" :class="{
                                            'active': list.order[column.name] === 'desc',
                                            'active-up': list.order[column.name] === 'asc'
                                        }"></span>
                    </div>
                    <div class="sorting-holder" v-if="column.type==='select'">
                      <input type="checkbox" v-model="checkAll">
                    </div>
                  </th>
                </tr>
                </thead>
                <tbody :key="item.ID" v-for="item in list.data" :class="{
                  'c-table-history-container': item.children && item.children.length > 1,
                  'active': showedSub(item.ID)
                  }">
                <tr :class="{
                  'c-table-row_active text-bold': item.children && item.children.length > 1
                  }">
                  <td :key="column.name" v-for="column in columnList">
                    <span v-if="column.type === 'icon' && item[column.key]" class="d-i-icon"
                          :class="item[column.key].type" :title="item[column.key].title"></span>
                    <span
                        :class="{'active': showedSub(item.ID)}"
                        @click="showSub(item.ID)"
                        v-if="column.type==='expand_children' && item.children && item.children.length > 1"
                        class="js-link-chevron icon-chevron"></span>
                    <span v-if="column.display_as === 'text' || column.display_as === 'text_path'"
                          :style="{
                      'white-space': column.modifiers.find(val => val === 'white-space-pre')?'pre':'',
                      'word-break': column.modifiers.find(val => val === 'break-all')?'normal':''
                    }"
                    >{{
                        shared.showField(column, item)
                      }}</span>
                    <router-link
                        :to="{name: 'CardContract', query: { _r: route.query._r }, params: { id: item['ID']}}"
                        v-if="column.display_as === 'router_link'">
                      {{ shared.showField(column, item) }}
                    </router-link>
                    <div class="sorting-holder" v-if="column.type==='select'">
                      <input type="checkbox" :value="item.ID" v-model="checked">
                    </div>
                  </td>
                </tr>
                <tr class="c-table-row_hidden" :key="i2.ID" v-for="i2 in item.children"
                    :class="{'active': showedSub(item.ID)}">
                  <td :key="column.name" v-for="column in columnList">
                    <span v-if="column.display_as === 'text' || column.display_as === 'text_path'"
                          :class="{'stl-ws-pre':column.modifiers.filter(val => val === 'white-space-pre')}">{{
                        shared.showField(column, i2)
                      }}</span>
                  </td>
                </tr>
                </tbody>
                <tbody>
                <tr v-if="list.meta.IS_CAN_BULK_STATUS_CHANGE || list.meta.IS_CAN_BULK_APPROVAL">
                  <td colspan="10">
                    <button v-if="list.meta.IS_CAN_BULK_STATUS_CHANGE" type="button" class="btn btn-blue c-btn-table"
                            data-bs-toggle="modal"
                            :disabled="checked.length === 0"
                            data-bs-target="#modal-edit-status">сменить статус
                    </button>
                    <button v-if="list.meta.IS_CAN_BULK_APPROVAL" type="button" class="btn btn-blue c-btn-table"
                            data-bs-toggle="modal"
                            :disabled="checked.length === 0"
                            data-bs-target="#modal-approve">согласовать
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
              <Pagination
                  :pages="pages"
                  v-model="currentPage"
                  :total="list.meta.total_filtered"
                  :perPage="currentLimit"
                  @update:perPage="currentLimit = $event"
              ></Pagination>
            </div>
          </div>
        </section>
        <!-- page-container -->
      </div>
    </section>
    <!-- content-wrapper -->
    <MainFooter>
      <div class="footer-right__controll">
        <button v-if="list.meta.IS_CAN_CREATE" class="btn btn-green"
                @click="shared.showModal('#modal-purchase-plan')">СОЗДАТЬ
          ЗАКУПКУ В ПЛАН
        </button>
      </div>
    </MainFooter>
    <!-- footer -->
  </div>
  <!-- main-wraper -->

</template>
